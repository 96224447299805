::-webkit-scrollbar {
  width: 20px;
  right: 100px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #dedede;
  background-clip: content-box;
  border: 8px solid transparent;
  border-radius: 10px;
}
