.transcript-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: horizontal;
}

.transcripts-list {
  height: calc(100vh - 142px);
  overflow-y: scroll;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-upload-container {
  background-image: url("../../assets/images/upload-file.svg");
  background-repeat: no-repeat;
  background-size: cover;
}