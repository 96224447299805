@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-size28 {
    @apply text-[28px] leading-[38px];
  }
  .text-size18 {
    @apply text-[18px] leading-[24px];
  }
  .text-size16 {
    @apply text-[16px] leading-[22px];
  }
  .text-size15 {
    @apply text-[15px] leading-[20px];
  }
  .text-size14 {
    @apply text-[14px] leading-[19px];
  }
}

html {
  font-family: Satoshi, system-ui, sans-serif;
}
